import { FC } from 'react'
import { SectionThreeFeaturesProps } from '@magal/models'
import { styled } from '@magal/styles'
import { Container, Media, SectionWrap } from '@magal/components'

const Wrapper = styled(Container, {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$40',
  color: '$green_09',
  '@lg': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
})

const Title = styled('h2', {
  projectFont: 'caps03',
})

const Subtitle = styled('p', {
  projectFont: 'body01',
  marginTop: '$20',
})

const Sidebar = styled('div', {
  display: 'grid',
  gridTemplateRows: 'min-content',
  justifyContent: 'center',
  textAlign: 'center',
  gridColumn: 'span 3',
  '@lg': {
    textAlign: 'left',
    justifyContent: 'initial',
    gridColumn: 'initial',
  },
})

const Features = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridColumn: 'span 3',
  gap: '$16',
  '@lg': {
    gap: '$32',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
})

const Feature = styled('div', {
  display: 'grid',
  backgroundColor: '$gray300',
  borderRadius: '$r2',
  justifyContent: 'center',
  padding: '$20',
  alignItems: 'end',
  '&:nth-child(3n)': {
    gridColumn: '1 / span 2',
    '@lg': {
      gridColumn: 'initial',
    },
  },
})

const FeatureTitle = styled('p', {
  projectFont: 'body01',
  textAlign: 'center',
})

const IconWrapper = styled('div', {
  margin: '$40 auto',
  width: '100%',
  maxWidth: '84px',
  display: 'block',
  '@lg': {
    maxWidth: '100px',
  },
})

export const SectionThreeFeatures: FC<SectionThreeFeaturesProps> = ({
  title,
  subtitle,
  features,
}) => {
  return (
    <SectionWrap type={'BORDERS'}>
      <Wrapper>
        <Sidebar>
          {title && <Title>{title}</Title>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Sidebar>
        <Features>
          {features?.map(({ title, icon }, index) => {
            return (
              <Feature key={index}>
                {icon && (
                  <IconWrapper>
                    <Media {...icon} sizes={'200px'} />
                  </IconWrapper>
                )}
                {title && <FeatureTitle>{title}</FeatureTitle>}
              </Feature>
            )
          })}
        </Features>
      </Wrapper>
    </SectionWrap>
  )
}
